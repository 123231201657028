// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #ecffec */ /* #e7ffe5 */ /* #d7f5d7 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #597557 */ /* #58be58 */ /* #3373CC */ /* #59bf59 */ /* 35% transparent version of looks-tertiary #529d4e */ /* 15% transparent version of looks-tertiary */ /* #4eb64e */ /* #FF661A */ /* #E64D00 */ /* #65cf63 */ /* #42bd44 */ /* #FFAB19 */ /* #FF8C1A */ /* #18bd0f */ /* #0fbd0f*/ /* #0b8e0b*/ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0fbd0f */ /* #0f8e0b */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .play-button_play-button_3MkN- {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    overflow: hidden;  /* Mask the icon animation */\n    width: 2.5rem;\n    height: 2.5rem;\n    background-color: hsla(120, 49%, 56%, 1);\n    color: hsla(0, 100%, 100%, 1);\n    border-radius: 50%;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n    transition: all 0.15s ease-out;\n} .play-button_play-button_3MkN- {\n    position: absolute;\n    top: .5rem;\n    z-index: auto;\n} .play-button_play-button_3MkN-:focus {\n    outline: none;\n} .play-button_play-icon_CX5nH {\n  width: 50%;\n} [dir=\"ltr\"] .play-button_play-button_3MkN- {\n    right: .5rem;\n} [dir=\"rtl\"] .play-button_play-button_3MkN- {\n    left: .5rem;\n}\n", ""]);
// Exports
exports.locals = {
	"play-button": "play-button_play-button_3MkN-",
	"playButton": "play-button_play-button_3MkN-",
	"play-icon": "play-button_play-icon_CX5nH",
	"playIcon": "play-button_play-icon_CX5nH"
};
module.exports = exports;
