import React, { Component } from 'react';
import Instructions from '../components/instructions/instructions.jsx'; // Import the Instructions component

class InstructionsContainer extends Component {
  render() {
    // Inline CSS for Flexbox container
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // height: '20vh',
      // width: '500px',
      // height: '100vh',
      // width: '100%',
      // border: '5px solid red'
      // backgroundColor: '#f5f5f5', // Light background color for contrast
    };

    // const instructionsStyle = {
    //   width: '50%', // Half the available width for instructions
    // };

    return (
      <div style={containerStyle}>
          <Instructions text="This is the instructions component that shows text based state. When the state is collapsed, 
      only a portion of the text is visible, but when expanded, the entire text is shown. If the text overflows, a scroll is added. also i am adding this line to test if only three lines are visible when the text overflows and then the scorll comes in to action Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." />
      </div>
    );
  }
}

export default InstructionsContainer;
