// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #ecffec */ /* #e7ffe5 */ /* #d7f5d7 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #597557 */ /* #58be58 */ /* #3373CC */ /* #59bf59 */ /* 35% transparent version of looks-tertiary #529d4e */ /* 15% transparent version of looks-tertiary */ /* #4eb64e */ /* #FF661A */ /* #E64D00 */ /* #65cf63 */ /* #42bd44 */ /* #FFAB19 */ /* #FF8C1A */ /* #18bd0f */ /* #0fbd0f*/ /* #0b8e0b*/ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0fbd0f */ /* #0f8e0b */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .turbo-mode_turbo-container_2ZNcq {\n    display: flex;\n    align-items: center;\n    padding: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n} .turbo-mode_turbo-icon_fjr8U {\n    margin: 0.25rem;\n} .turbo-mode_turbo-label_-1oMs {\n    font-size: 0.625rem;\n    font-weight: bold;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: hsla(38, 100%, 55%, 1);\n    white-space: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"turbo-container": "turbo-mode_turbo-container_2ZNcq",
	"turboContainer": "turbo-mode_turbo-container_2ZNcq",
	"turbo-icon": "turbo-mode_turbo-icon_fjr8U",
	"turboIcon": "turbo-mode_turbo-icon_fjr8U",
	"turbo-label": "turbo-mode_turbo-label_-1oMs",
	"turboLabel": "turbo-mode_turbo-label_-1oMs"
};
module.exports = exports;
