// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".settings-menu_icon_3QaRk {\n    width: 1.5rem;\n}\n\n.settings-menu_theme-label_nv_1M {\n    flex: 1;\n}\n\n.settings-menu_option_3rMur {\n    display: flex;\n    align-items: center;\n    gap: .5rem;\n}\n\n.settings-menu_check_3ssaq {\n    margin: 0 .25rem 0 0;\n    visibility: hidden;\n}\n\n[dir=\"rtl\"] .settings-menu_check_3ssaq {\n    margin: 0 0 0 .25rem;\n}\n\n.settings-menu_selected_2pjxx {\n    visibility: visible;\n}\n\n.settings-menu_submenu-label_r-gA3 {\n    flex: 1;\n}\n\n.settings-menu_dropdown-label_3f68c {\n    margin: 0 0.5rem 0 0.25rem;\n}\n\n[dir=\"rtl\"] .settings-menu_dropdown-label_3f68c {\n    margin: 0 0.25rem 0 0.5rem;\n}\n\n.settings-menu_expand-caret_u2sS- {\n    transform: rotate(-90deg);\n}\n\n[dir=\"rtl\"] .settings-menu_expand-caret_u2sS- {\n    transform: rotate(90deg);\n}\n\n/* Extra padding to deal with scrollbar overlapping options in Firefox */\n\n[dir=\"ltr\"] .settings-menu_language-menu-item_3M-8n {\n    padding-right: 1rem;\n}\n\n[dir=\"rtl\"] .settings-menu_language-menu-item_3M-8n {\n    padding-left: 1rem;\n}\n\n.settings-menu_language-submenu_2LYn6 > ul {\n    max-height: calc(100vh - 10rem); /* Fallback if dvh not supported */\n    max-height: calc(100dvh - 10rem);\n}\n\n@media only screen and (max-width: 1024px) {\n    .settings-menu_dropdown-label_3f68c {\n        display: none;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "settings-menu_icon_3QaRk",
	"theme-label": "settings-menu_theme-label_nv_1M",
	"themeLabel": "settings-menu_theme-label_nv_1M",
	"option": "settings-menu_option_3rMur",
	"check": "settings-menu_check_3ssaq",
	"selected": "settings-menu_selected_2pjxx",
	"submenu-label": "settings-menu_submenu-label_r-gA3",
	"submenuLabel": "settings-menu_submenu-label_r-gA3",
	"dropdown-label": "settings-menu_dropdown-label_3f68c",
	"dropdownLabel": "settings-menu_dropdown-label_3f68c",
	"expand-caret": "settings-menu_expand-caret_u2sS-",
	"expandCaret": "settings-menu_expand-caret_u2sS-",
	"language-menu-item": "settings-menu_language-menu-item_3M-8n",
	"languageMenuItem": "settings-menu_language-menu-item_3M-8n",
	"language-submenu": "settings-menu_language-submenu_2LYn6",
	"languageSubmenu": "settings-menu_language-submenu_2LYn6"
};
module.exports = exports;
