import React, { Component } from 'react';
import styles from './instructions.css'; // Assuming you are using a separate CSS file for styling
import classNames from "classnames";

class Instructions extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const { expanded } = this.state;
    const textToShow = expanded
      ? this.props.text // Show full text (scrollable)
      : this.props.text.split(' ').slice(0, 8).join(' ') + '...'; // Show only the first 12 words

    return (
      <div className={styles.instructionsContainer}>
        <div
          className={classNames(
            styles.instructionsText,
            expanded ? styles.expanded : styles.collapsed
          )}
        >
          {textToShow}
          <span onClick={this.toggleExpanded} className={styles.toggleBtn}>
            {expanded ? 'read less' : 'read more'}
          </span>
        </div>
      </div>
    );
  }
}

export default Instructions;
