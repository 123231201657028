// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #ecffec */ /* #e7ffe5 */ /* #d7f5d7 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #597557 */ /* #58be58 */ /* #3373CC */ /* #59bf59 */ /* 35% transparent version of looks-tertiary #529d4e */ /* 15% transparent version of looks-tertiary */ /* #4eb64e */ /* #FF661A */ /* #E64D00 */ /* #65cf63 */ /* #42bd44 */ /* #FFAB19 */ /* #FF8C1A */ /* #18bd0f */ /* #0fbd0f*/ /* #0b8e0b*/ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0fbd0f */ /* #0f8e0b */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .stop-all_stop-all_1Y8P9 {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n} .stop-all_stop-all_1Y8P9:hover {\n    background-color: hsla(120, 40%, 45%, 1);\n} .stop-all_stop-all_1Y8P9 {\n    opacity: 0.5;\n} .stop-all_stop-all_1Y8P9.stop-all_is-active_3nog0 {\n    opacity: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"stop-all": "stop-all_stop-all_1Y8P9",
	"stopAll": "stop-all_stop-all_1Y8P9",
	"is-active": "stop-all_is-active_3nog0",
	"isActive": "stop-all_is-active_3nog0"
};
module.exports = exports;
