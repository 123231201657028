// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #ecffec */ /* #e7ffe5 */ /* #d7f5d7 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #597557 */ /* #58be58 */ /* #3373CC */ /* #59bf59 */ /* 35% transparent version of looks-tertiary #529d4e */ /* 15% transparent version of looks-tertiary */ /* #4eb64e */ /* #FF661A */ /* #E64D00 */ /* #65cf63 */ /* #42bd44 */ /* #FFAB19 */ /* #FF8C1A */ /* #18bd0f */ /* #0fbd0f*/ /* #0b8e0b*/ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0fbd0f */ /* #0f8e0b */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .meter_green_oUPv2 {\n    fill: rgb(171, 220, 170);\n    stroke: rgb(174, 211, 168);\n} .meter_yellow_3uz-j {\n    fill: rgb(251, 219, 130);\n    stroke: rgb(239, 212, 134);\n} .meter_red_1aMUm {\n    fill: rgb(251, 194, 142);\n    stroke: rgb(235, 189, 142);\n} .meter_mask-container_11m_n {\n    position: relative;\n} .meter_mask_B-cd7 {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    transform-origin: top;\n    will-change: transform;\n    background: hsla(120, 100%, 94%, 1);\n    opacity: 0.75;\n}\n", ""]);
// Exports
exports.locals = {
	"green": "meter_green_oUPv2",
	"yellow": "meter_yellow_3uz-j",
	"red": "meter_red_1aMUm",
	"mask-container": "meter_mask-container_11m_n",
	"maskContainer": "meter_mask-container_11m_n",
	"mask": "meter_mask_B-cd7"
};
module.exports = exports;
